import {
    Organization,
    SignInType,
    SubscriptionType,
    UserAIStatsHistory,
    UserDetails,
} from "@knowt/syncing/graphql/schema";

export const TEMP_CACHED_SIGN_IN_SOURCE_KEY = "tempCachedSignInSource";
export const SIGN_UP_TRACKED = "isSignUpTracked";

export const FREE_USAGE_LIMIT: Required<Omit<UserAIStatsHistory, "periodEnd" | "cost" | "__typename">> = {
    // KnowtAI
    prompts: 50,
    tokens: 20_000,

    // Videos
    videos: 2,
    mins: 80,
    vNotes: 1,
    vSets: 1,

    // PDFs
    pdfs: 2,
    pages: 80,
    pNotes: 1,
    pSets: 1,

    // Notes
    nTests: 5,
    nSets: 5,

    // Media
    mSets: 1,
    mNotes: 1,

    // Scans
    scans: 200,
    aiV: 5,

    // Teacher Tools
    tools: 50,
    assignments: 2,

    // Chats
    chats: 20,

    // Explain why I'm wrong
    explain: 20,
    frq: 10,
};

export const AI_USAGE_LIMITS: {
    [key in SubscriptionType]: Required<Omit<UserAIStatsHistory, "periodEnd" | "cost" | "__typename">>;
} = {
    [SubscriptionType.BASIC]: FREE_USAGE_LIMIT,
    [SubscriptionType.SUPPORTER]: {
        ...FREE_USAGE_LIMIT,

        // Explain why I'm wrong
        explain: 2_000,
        frq: 100,
    },
    [SubscriptionType.PRO]: {
        // KnowtAI
        prompts: 1_000,
        tokens: 500_000,

        // Videos
        videos: 5,
        mins: 200,
        vNotes: 5,
        vSets: 5,

        // PDFs
        pdfs: 5,
        pages: 200,
        pNotes: 5,
        pSets: 5,

        // Notes
        nTests: 50,
        nSets: 50,

        // Media
        mSets: 5,
        mNotes: 5,

        // Scans
        scans: 200,
        aiV: 15,

        // Teacher Tools
        tools: 100,
        assignments: 5,

        // Chats
        chats: 200,

        // Explain why I'm wrong
        explain: 5_000,
        frq: 100,
    },
    [SubscriptionType.LIMITLESS]: {
        // Technically, there is no limit, but we should set a some sort of limit so that we don't get charged a ton
        // plus we know who is using it the most, or spamming us

        // KnowtAI
        prompts: 10_000,
        tokens: 1_000_000,

        // Videos
        videos: 100,
        mins: 4_000,
        vNotes: 100,
        vSets: 100,

        // PDFs
        pdfs: 100,
        pages: 4_000,
        pNotes: 100,
        pSets: 100,

        // Notes
        nTests: 500,
        nSets: 500,

        // Media
        mSets: 100,
        mNotes: 100,

        // Scans
        scans: 1_000,
        aiV: 100,

        // Teacher Tools
        tools: 2_000,
        assignments: 100,

        // Chats
        chats: 1_000,

        // Explain why I'm wrong
        explain: 20_000,
        frq: 500,
    },
    // same as student supporter
    [SubscriptionType.TEACHER_PRO]: {
        ...FREE_USAGE_LIMIT,

        // Explain why I'm wrong
        explain: 2_000,
        frq: 100,
    },
    [SubscriptionType.TEACHER_LIMITLESS]: {
        // Technically, there is no limit, but we should set a some sort of limit so that we don't get charged a ton
        // plus we know who is using it the most, or spamming us

        // KnowtAI
        prompts: 10_000,
        tokens: 1_000_000,

        // Videos
        videos: 100,
        mins: 4_000,
        vNotes: 100,
        vSets: 100,

        // PDFs
        pdfs: 100,
        pages: 4_000,
        pNotes: 100,
        pSets: 100,

        // Notes
        nTests: 500,
        nSets: 500,

        // Media
        mSets: 100,
        mNotes: 100,

        // Scans
        scans: 1_000,
        aiV: 100,

        // Teacher Tools
        tools: 2_000,
        assignments: 100,
        // Chats
        chats: 1_000,

        // Explain why I'm wrong
        explain: 20_000,
        frq: 500,
    },
};

export type LocalUnauthUser = {
    user: null | undefined;
    organization: null | undefined;
    signInType: never;
    serverSyncTime: number;
};

export type LocalAuthUser = {
    user: UserDetails;
    organization: Organization | null | undefined;
    signInType: SignInType;
    serverSyncTime: number;
};

export type LocalUser = LocalAuthUser | LocalUnauthUser;
